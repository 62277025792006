<template>
	<div class="content">
		<Portfolio></Portfolio>
		<section class="fullPageSectionContainer setMenuColor" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type="2"></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		head () {
			return {
				link: [
					{ rel: 'canonical', href: 'https://artrockets.ru' + this.$route.path + '/' }
				]
			}
		},
		metaInfo: {
			title: 'Портфолио веб-студии | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Выполненные проекты компании Артрокетс. Корпоративные сайты и интернет-магазины, онлайн сервисы, а также мобильные приложения.' },
				{ vmid: 'og:title', property: 'og:title', content: 'Портфолио веб-студии | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Выполненные проекты компании Артрокетс. Корпоративные сайты и интернет-магазины, онлайн сервисы, а также мобильные приложения.' },
			],
		},
		data: () => ({
			
		}),
		name: 'portfolio',
		components: {
			Portfolio: () => import('@/components/new/Portfolio'),
			Feedback: () => import('@/components/new/Forms/Feedback')
		}
	}
</script>
